<template>
  <div class="holder">
    <h2>label: (d) => d.label</h2>
    <p>指定labe字段</p>

    <h1>
      个股的估值图
      <span @click="playIn1('refEvaluating')">入场</span>
      <span @click="playOut('refEvaluating')">出场</span>
    </h1>
    <evaluating-chart ref="refEvaluating" id="refEvaluating" customClass="evaluating_chart" />
  </div>
</template>
<script>
import evaluatingChart from "@/components/evaluatingChart/index_v1.0.vue";
import distData from "./distData.json";
export default {
  data: function () {
    return {};
  },
  components: { evaluatingChart },
  methods: {
    playIn1(refName) {
      this.$refs[refName].EvaluatingChart(distData.dataArr, {
        width: 600,
        height: 300,
        marginTop: 60,
        marginBottom: 40,
        marginLeft: 50,
        marginRight: 80,
        duration: 2000,
        delay: 30,
        ease: "easeCubicOut",
      });
    },

    playOut(refName) {
      this.$refs[refName].playOut();
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.holder {
  padding: 20px;
}
h1 {
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: normal;
  color: #333;
  span {
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    background: #999;
    color: #fff;
    user-select: none;
    margin: 0 3px;
  }
  span:nth-of-type(1) {
    background: #447746;
  }
  span:nth-of-type(2) {
    background: #953e23;
  }
}
h2 {
  font-size: 15px;
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
  .new {
    color: #fff;
    background: #f00;
    padding: 0.5rem;
    border-radius: 0.3125rem;
    margin-right: 0.625rem;
  }
}
p {
  margin-bottom: 20px;
}

.evaluating_chart {
  margin-bottom: 20px;
  min-height: 200px;
  color:#fff;
  background: #333;
  background: -webkit-linear-gradient(top right, #333, #666);
  background: -moz-linear-gradient(top right, #333, #666);
  background: linear-gradient(to bottom left, #333, #666);
  display: inline-block;
  /deep/ .tick {
    text{ font-size: 14px;}
  }
  /deep/ .line {
    stroke: #ff7700;
    stroke-width: 2px;
  }
  /deep/ .area_top {
    stop-color: #ff7700;
    stop-opacity: 0.42;
  }
  /deep/ .area_bottom {
    stop-color: #ff77003e;
    stop-opacity: 0.26;
  }
  /deep/ .eva_ave_rect {
    fill:none;
    stroke-width:1px;
    stroke:#c5c5c5;
  }
  /deep/ .eva_ave_line {
    stroke-width:1px;
    stroke:#c5c5c5;
    stroke-dasharray: 4px 2px;
  }
  /deep/ .eva_ave_txt {
    font-size: 14px;
    fill:#c5c5c5;
  }
  /deep/ .eva_current_line {
    stroke: #ff7700;
    stroke-dasharray: 4px 2px;
  }
  /deep/ .eva_current_dot {
    fill: #ff7700;
  }
  /deep/ .eva_current_text {
    fill: #ff7700;
  }
}
</style>
